import React from "react";
import { useState } from "react";
import { handleDownloadBtnClick } from "helpers";

const Alert = () => {
  const [isAlertVisible, toggleAlertVisible] = useState(true);

  return (
    <>
      {isAlertVisible && (
        <div className="alert d-flex d-md-none">
          <div className="alert__text">
            <div onClick={() => toggleAlertVisible(false)}>
              <img
                alt="close-icon"
                className=""
                src={require("assets/images/icons/close_icon.svg").default}
              />
            </div>
            <p className="">Mobil tətbiqi yükləmək üçün</p>
          </div>
          <div className="alert__button">
            <button className="btn btn-black" onClick={handleDownloadBtnClick}>
              Yüklə
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Alert;
