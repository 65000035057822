import React from "react";

export const Terms = () => (
  <div className="ps-4 pt-2 pb-4 ordered-list">
      <ol>
          <li>
              <h5>MÜQAVİLƏNİN PREDMETİ</h5><p>Bu müqavilə Şirkətin məhsulu olan və kənd təsərrüfatı sahəsində fəaliyyət göstərən şəxslər üçün rəqəmsal həllər təqdim edən “Yonca” mobil tətbiqinin istifadəçiləri ilə Şirkət arasındakı münasibətləri tənzimləyir. </p>
          </li><br/>
          <li>
            <h5>ÜMUMİ MÜDDƏALAR</h5>
            <ol>
                  <li>İstifadəçi “Yonca” mobil tətbiqində qeydiyyatdan keçməzdən əvvəl bu Müqavilənin şərtləri ilə tanış
                      olmalıdır. İstifadəçinin Mobil tətbiqindən qeydiyyatdan keçməsi Müqavilənin tələbləri ilə
                      razılaşması kimi qəbul edilir.
                  </li>
                  <li>Müqavilənin tələblərinin rəqəmsal sahədəki irəliləyişlərə uyğunlaşdırılması məqsədilə Azərbaycan
                      Respublikasının qanunvericiliyi də nəzərə alınmaqla, Şirkət tərəfindən birtərəfli qaydada
                      təkmilləşdirilə bilər. Müqavilənin tələblərinə dəyişiklik edildikdə İstifadəçi mobil tətbiqinə
                      daxil olan elektron bildiriş vasitəsilə məlumatlandırılır.
                  </li>
                  <li>Müqavilənin bir hissəsinin qüvvəsini itirməsi və ya ləğvi onun qüvvəsini itirməsinə səbəb olmur.
                  </li>
                  <li>Şirkət Fərdi İdentifikasiya Nömrəsi və ya “Asan imza” vasitəsilə Mobil tətbiqdən qeydiyyatdan
                      keçmiş və Mobil tətbiqdən onun funksional təyinatı üzrə istifadə edən istənilən şəxsi İstifadəçi
                      kimi tanıyır.
                  </li>
                  <li>İstifadəçi bu Müqavilənin şərtlərini qəbul edərək qeydiyyat zamanı daxil etdiyi məlumatların
                      düzgünlüyünü təsdiq edir və həmin məlumatların düzgünlüyü, dolğunluğu və etibarlılığı üçün bütün
                      məsuliyyəti öz üzərinə götürür. İstifadəçi təqdim olunan məlumatlarında hər hansı səhv və ya
                      qeyri-dəqiqlik nəticəsində yaranan bütün risklərin və zərərlərin aradan qaldırılmasını öhdəsinə
                      götürür və bu cür risk və zərərlə bağlı Şirkətə qarşı hər hansı iddiasının olmayacağını bəyan
                      edir.
                  </li>
                  <li>Mobil tətbiqin istifadəsinə başlamazdan əvvəl İstifadəçi qeydiyyat prosedurunu keçməklə onun
                      aktivləşdirilməsini həyata keçirir.
                  </li>
                  <li>İstifadəçi “Razıyam” düyməsini basmaqla bu Müqavilə ilə tanış olduğunu, onun bütün şərtlərini öz
                      adından və ya təmsil etdiyi hüquqi şəxsin adından qəbul etdiyini, bu razılığın öz azad iradə
                      ifadəsi olduğunu, həmin şərtlərə vaxtında və dəqiq əməl edəcəyini bəyan edir.
                  </li>
                  <li>İstifadəçi elektron qaydada bağlanılan bu Müqavilənin (və ona əlavə edilmiş digər sənədlərin)
                      Azərbaycan Respublikasının qanunvericiliyinə uyğun olaraq “müqavilə” hesab olunduğunu qəbul edir.
                  </li>
                  <li>Bu Müqavilə Bakı şəhərində bağlanılmış hesab olunur.</li>
                  <li>Şirkət istənilən vaxt hazırkı Müqaviləyə dəyişikliklər və (və ya) əlavələr edə bilər. Bu zaman
                      dəyişdirilmiş Müqavilə barədə İstifadəçiyə proqramdan növbəti istifadədən öncə elektron bildiriş
                      vasitəsilə məlumat verilir və yeni şərtlərin qəbul edilməsi xahiş olunur. İstifadəçinin Mobil
                      tətbiqdən istifadəyə davam etməsi dəyişiklikləri (və ya əlavələri) təsdiq etməsi hesab olunur. Bu
                      Müqavilənin şərtləri ilə razı olmadıqda Mobil tətbiqin istifadəsindən imtina edə bilər.
                  </li>
                  <li>İstifadəçi bu Müqavilənin şərtlərini qəbul etdikdən və qeydiyyat prosedurunu tamamladıqdan sonra
                      Mobil tətbiqdən istifadə etmə hüququnu əldə edir.
                  </li>
                  <li>Bu müqavilənin məqsədləri üçün istifadə olunan əsas anlayışlar aşağıdakı mənaları ifadə edir:
                      <br/>“Mobil tətbiq” – İstifadəçi tərəfindən İstifadəçinin mobil cihazına yüklənən, kənd təsərrüfatı sahəsində fəaliyyət göstərən şəxslər üçün rəqəmsal həllər təqdim edən “Yonca” mobil tətbiqi;
                      <br/>“Şirkət” – Azərbaycan Respublikasının qanunvericiliyinə uyğun olaraq dövlət qeydiyyatına alınmış, Mobil proqramı vasitəsilə dövlət və qeyri-dövlət qurumlarının elektron xidmətlərini təqdim edən, onun inkişafı, təşviqi, marketinq və biznes fəaliyyətini təmin edən “Digital Umbrella” Məhdud Məsuliyyətli Cəmiyyəti;
                      <br/>“İstifadəçi” – Mobil tətbiqdən istifadə etmək məqsədilə qeydiyyatdan keçmiş və bu Müqavilənin şərtlərini qəbul etmiş rezident və ya qeyri-rezident fiziki şəxslər və ya nümayəndəsi vasitəsilə qeydiyyatdan keçmiş hüquqi şəxslər;
                      <br/>“Mobil cihaz” – İstifadəçi tərəfindən istifadə olunan iOS və Android əməliyyat sistemlərini dəstəkləyən mobil telefon, smartfon, planşet və sair bu kimi elektron cihaz;
                      <br/>“Xidmət” – Mobil tətbiq vasitəsilə İstifadəçinin sərəncamına verilmiş xidmətlər. Xidmətlərin siyahısı əvvəlcədən bildiriş göndərilmədən dəyişdirilə bilər
                      <br/>“Xidmət haqqı” – Mobil tətbiq vasitəsilə xidmətlərin göstərilməsi müqabilində Şirkət tərəfindən İstifadəçidən tutulan haqq;
                  </li>
              </ol>
          </li><br/>
          <li>
              <h5>İSTİFADƏÇİNİN HÜQUQ VƏ ÖHDƏLİKLƏRİ</h5>
              <ol>
                  <li>İstifadəçinin hüquqları:
                    <ol>
                        <li>Bu Müqaviləyə uyğun olaraq mobil tətbiqdən istifadə etmək;</li>
                        <li>Mobil tətbiqdə şəxsi hesaba sahib olmaq, təklif olunan xidmətlərdən istifadə etmək;</li>
                        <li>Mobil tətbiqin istifadəsindən imtina etmək;</li>
                        <li>İstifadə edilmiş xidmətlər barədə bildirişlər, məlumatlar, çıxarışlar almaq;</li>
                        <li>Fərdi məlumatlarının qanunvericiliyə uyğun olaraq mühafizə edilməsini tələb etmək;</li>
                        <li>Şirkətə müraciətlər və iddialar təqdim etmək, digər ərizələri göndərmək;</li>
                    </ol>
                  </li>
                  <li>İstifadəçinin öhdəlikləri:
                      <ol>
                          <li>Bu Müqavilənin tələblərinə riayət etmək;</li>
                          <li>Mobil tətbiqdən bu Müqavilənin şərtləri çərçivəsində yalnız təklif olunan xidmətlərin icrası üçün istifadə etmək;</li>
                          <li>Mobil tətbiqdən qanunverciliklə müəyyən olunmuş və icazə verilmiş məqsədlər xaricində, habelə Şirkətin qanuni mənafelərinə zidd olan məqsədlər üçün istifadə etməmək; </li>
                          <li>Şirkətə doğru və aktual (hazırki zaman mövcud olan) məlumatlar təqdim etmək;</li>
                          <li>Bildirişlərin göndərilməsi üçün istifadə edilən əlaqə məlumatlarında dəyişikliklərin edildiyi təqdirdə Şirkətə dərhal xəbər vermək;</li>
                          <li>Məbil tətbiqdən istifadəyə dair məlumatlarının məxfiliyini təmin etmək, başqa şəxslərə verməmək;</li>
                          <li>Şirkətin əqli mülkiyyət hüququna hörmət etmək;</li>
                          <li>Qanunvericiliyin tələblərinə riayət etmək.</li>
                      </ol>
                  </li>
              </ol>
          </li><br/>
          <li>
            <h5>ŞİRKƏTİN HÜQUQ VƏ ÖHDƏLİKLƏRİ</h5>
            <ol>
                <li>Şirkətin hüquqları:
                    <ol>
                        <li>Mobil tətbiq vasitəsilə xidmətlər təklif etmək və bu xidmətlər müqabilində xidmət haqqı əldə etmək;</li>
                        <li>Mobil tətbiqin istifadəsi ilə bağlı müəlliflik hüquqlarına sahib olmaq;</li>
                        <li>İstifadəçilərin məlumatlarını əldə etmək və onlardan “Fərdi məlumatlar haqqında” Azərbaycan Respublikasının Qanununa, “Kommersiyya sirri haqqında” Azərbaycan Respublikasının Qanununa və bu Müqaviləyə uyğun olaraq istifadə etmək;</li>
                        <li>İstifadəçi tərəfindən qanunvericiliyin və ya bu Müqavilənin tələblərinin pozulduğu hallarda həmin İstifadəçinin Mobil tətbiqdən bütövlükdə və ya onun hər hansı hissəsindən cari və ya gələcək istifadəsi imkanlarını məhdudlaşdırmaq;</li>
                        <li>Bu Müqavilənin şərtlərini, Mobil tətbiq vasitəsilə təklif edilən elektron xidmətlərin siyahısını əvvəlcədən xəbər verməməklə dəyişdirmək;</li>
                        <li>“cookie-faylları”ndan və digər məlumat toplama texnologiyalarından istifadə etməklə İstifadəçinin internet üzərindən əməliyyatlar və ya üçüncü tərəflərin internet saytlarında yerləşdirilən elanları üzrə məlumatlar toplamaq;</li>
                        <li>İstifadəçiyə məlumat xarakterli ismarıclar (email, sms və digər üsullarla) göndərmək; </li>
                    </ol>
                </li>
                <li>Şirkətin öhdəlikləri:
                    <ol>
                        <li>Xidmət haqqı müqabilində İstifadəçiyə elektron xidmət göstərmək;</li>
                        <li>Mobil tətbiqin daimi olaraq işlək vəziyyətə qalması üçün mütəmadi olaraq tədbirlər görmək;</li>
                        <li>Qanunvericiliyin tələblərinə riayət etmək.</li>
                    </ol>
                </li>
            </ol>
          </li><br/>
          <li>
              <h5>ŞİRKƏT TƏRƏFİNDƏN TƏQDİM EDİLƏN XİDMƏTLƏR VƏ ONLARIN DƏYİŞDİRİLMƏSİ</h5>
              <ol>
                  <li>Şirkət Mobil tətbiq vasitəsilə aşağıdakılar ilə məhdudlaşdırılmayan, dəyişdirilə bilən xidmətləri təqdim edir:
                    <ol>
                        <li>Aqrar fəaliyyətlə bağlı mütəmadi olaraq ümumi bildirişlərin əldə olunması;</li>
                        <li>Aqrar sahədə son xəbərlər və təlimlər barədə məlumatlandırılma;</li>
                        <li>Aqrar xidmət məntəqələrinin axtarışı;</li>
                        <li>“Elektron kənd təsərrüfatı” informasiya sistemində (EKTİS) mövcud fermer hesabı üzrə əkinlərin bəyan edilməsi  və əkin subsidiyası üzrə müraciətlərin göndərilməsi və izlənilməsi;</li>
                        <li>EKTİS-də mövcud fermer kabinetindəki fəaliyyətlə bağlı fərdi bildirişlərin əldə edilməsi;</li>
                        <li>Hava proqnozunun inzibati ərazilər üzrə göstərilməsi.</li>
                    </ol>
                  </li>
                  <li>stifadəçi bu Müqaviləni təsdiq etməklə qəbul edir ki, Şirkət İstifadəçi ilə əvvəlcədən razılaşdırmadan istənilən Xidmətə əlavə və ya dəyişiklik etmək, habelə Xidməti ləğv etmək hüququnu özündə saxlayır. Bu zaman dəyişiklik barədə İstifadəçiyə proqramdan elektron bildiriş vasitəsilə məlumat verilir. İstifadəçinin Mobil tətbiqdən istifadəyə davam etməsi dəyişiklikləri (və ya əlavələri) təsdiq etməsi hesab olunur. İstifadəçi bu Müqavilənin şərtləri ilə razı olmadıqda Mobil tətbiqin istifadəsindən imtina edə bilər.</li>
                  <li>Xidmətləri haqqında məlumatlar elektron poçt, SMS, POP-UP texnologiyası və ya tətbiq bildirişlərindən (app notifications) hər hansı biri vasitəsilə İstifadəçiyə çatdırıla bilər. Məlumatların çatıdırılma üsulu Şirkət tərəfindən müəyyən edilir.</li>
              </ol>
          </li><br/>
          <li>
              <h5>MÜQAVİLƏNİN QİYMƏTİ</h5>
              <ol>
                  <li>İstifadəçi tərəfindən Mobil tətbiq üzərindən göstərilmiş elektron xidmətlərin istifadəsinə  görə illik 5 manat məbləğində haqq ödənilir.</li>
                  <li>Xidmət haqqının məbləği, ödəniş üsulları, üzvlük müddəti barədə məlumatlar Mobil tətbiqdə qeydiyyatdan keçən zaman İstifadəçiyə bildirilir</li>
                  <li>İllik abunəlik dövrü bitdikdən sonra İstifadəçinin kart hesabından avtomatik tutulma olmur və onun xidmətlərdən istifadə hüququna növbəti ödənişədək xitam verilir.</li>
                  <li>Yalnız İstifadəçi tərəfindən növbəti ödəniş edildikdən sonra onun üzvlüyü bərpa olunur.</li>
                  <li>Xidmət haqqı bank kartı və ya onlayn ödəniş platformaları vasitəsilə edilə bilər.</li>
                  <li>Şirkət əvvəlcədən bildiriş etmədən istənilən vaxt xidmət haqqını dəyişdirmək hüququna malikdir. Dəyişdirilmiş xidmət haqqı artıq ödənilmiş xidmətlərə şamil edilmir.</li>
                  <li>İstifadəçi dəyişdirilmiş xidmət haqqı ilə razılaşmadıqda Mobil tətbiqin istifadəsindən imtina edə bilər. Xidmət haqqı dəyişdirildikdən sonra İstifadəçi tərəfindən Mobil tətbiqin istifadəsinə davam edilməsi xidmət haqqı ilə razılaşma kimi qəbul olunacaqdır və gələcəkdə xidmət haqqı İstifadəçi tərəfindən mübahisələndirilməyəcəkdir.</li>
              </ol>
          </li><br/>
          <li>
              <h5>İSTİFADƏÇİNİN ONLAYN ƏMƏLİYYATLAR ÜZRƏ MƏLUMATLARININ TOPLANMASINA VƏ İSTİFADƏSİNƏ RAZILIĞI</h5>
              <ol>
                  <li>Şirkət “cookie-faylları”ndan və digər məlumat toplama texnologiyalarından istifadə etməklə İstifadəçinin internet üzərindən əməliyyatlar və ya üçüncü tərəflərin internet saytlarında yerləşdirilən elanları üzrə məlumatları toplaya bilər.</li>
                  <li>Şirkət İstifadəçiyə məlumat xarakterli ismarıclar (email, sms və digər üsullarla) göndərmək hüququndadır. İstifadəçi reklam xarakterli ismarıcların alınmasına razılıq verir.</li>
                  <li>İstifadəçi bu Müqavilənin şərtlərini qəbul etməklə, öz fərdi və fərdi olmayan məlumatlarının Şirkət tərəfindən bu müqavilənin 8-ci bəndinin müddəalarına uyğun olaraq toplanılması, saxlanılması, istifadəsi və qorunmasına icazə vermiş olur. Əldə edilmiş məlumatlardan reklam, tədqiqat və digər bu kimi məqsədlərlə istifadə edilə bilər.</li>
              </ol>
          </li><br/>
          <li>
              <h5>MƏXFİ MƏLUMATLARDAN İSTİFADƏYƏ DAİR TƏLƏBLƏR</h5>
              <ol>
                  <li>Bu Müqavilənin müddəalarına əsasən “Məxfi Məlumatlar” dedikdə “Fərdi məlumatlar haqqında” və “Kommersiya sirri haqqında” Azərbaycan Respublikasının Qanunlarında nəzərdə tutulan məlumatlar başa düşülür.</li>
                  <li>İstifadəçi tərəfindən açıqlanmazdan əvvəl açıq informasiya mənbələrindən ilə artıq Şirkətə məlum olan və ya İstifadəçinin özu haqqında yaydığı məlumatlar “Məxfi Məlumatlara” daxil edilmir.</li>
                  <li>Məxfi Məlumatların üçüncü şəxslərə açıqlanması bu Müqaviləyə əlavə olaraq hazırlanmış Məxfilik Siyasətinə uyğun həyata keçirilir.</li>
                  <li>Məxfi məlumatlardan qanunvericiliyin, bu Müqavilənin və ona əlavə olaraq hazırlanmış Məxfilik Siyasətinin müddəalarına zidd şəkildə istifadə edilməsi qadağandır.</li>
                  <li>Şirkət Məxfi Məlumatlardan icazəsiz istifadə və ya açıqlama halı aşkar edərsə, bu haqda İstifadəçiyə məlumat verir və baş vermiş pozuntunun aradan qaldırılması üçün zəruri tədbirlər görür.</li>
              </ol>
          </li><br/>
          <li>
              <h5>ŞİRKƏTİN MƏSULİYYƏTİ VƏ ONU İSTİSNA EDƏN HALLAR</h5>
              <ol>
                  <li>Şirkət ödənilmiş xidmət haqqı müqabilində bu Müqavilənin 5-ci maddəsində göstərilən xidmətlərdən istifadəni təmin etmədikdə ödənilmiş məbləğ 10 iş günü müddətində İstifadəçiyə geri qaytarılır.</li>
                  <li>Şirkət fors-major halların təsiri nəticəsində Müqavilə üzrə üzərinə düşən öhdəliklərin tamamilə və ya qismən icra edilməməsi üçün məsuliyyətdən azad olunurlar.</li>
                  <li>Şirkət Fors-major hallarının təsiri nəticəsində Müqavilə üzrə öhdəliklərinin hamısını və ya bir qismini yerinə yetirə bilmədikdə baş vermiş gecikmə və ya yerinə yetirməmə hallarına görə məsuliyyət daşımır. “Fors-major halı” dedikdə, belə halın təsirinə məruz qaldığını iddia edən Tərəfin ağlabatan nəzarətindən kənar hər hansı bir hadisə və ya hal, o cümlədən, təbii fəlakətlər, elan edilmiş və ya edilməmiş müharibə, yanğın, ümumi tətillər (təsirə məruz qalmış Tərəfin işçilərinin işə çıxmama halları istisna olmaqla), lokautlar (təsirə məruz qalmış Tərəfin işçilərinin lokautu istisna olmaqla) və ya işçilərlə yaranan digər mübahisələr (təsirə məruz qalmış Tərəf və onun işçiləri istisna olmaqla), iğtişaşlar, qiyamlar, epidemiya, pandemiya, epizootiya, hər hansı bir dövlət orqanının fəaliyyətini davam etdirilməsini qeyri-mümkün edən normativ hüquqi və ya inzibati aktlar daxil olmaqla, lakin bunlarla məhdudlaşmayan hallar aiddir.</li>
                  <li>Şirkət hər biri istinad etdiyi fors-major halın mövcudluğunu sübut etməyə borcludur və bu halın baş verdiyi haqqında digər İstifadəçiyə 3 gün ərzində məlumat verməlidir.</li>
              </ol>
          </li><br/>
          <li>
              <h5>MÜBAHİSƏLƏRİN HƏLLİ</h5>
              <ol>
                  <li>Müqaviləyə, onun təfsirinə və ya ondan irəli gələn bütün məsələlərin həllinə Azərbaycan Respublikasının qüvvədə olan qanunvericiliyi tətbiq edilir.</li>
                  <li>Tərəflər arasında yaranmış mübahisələr danışıqlar yolu ilə həll edilir.</li>
                  <li> öz aralarında 1 ay müddətində pretenziya irəli sürülməsi qaydasında həll edirlər.Bu Müqavilə üzrə yaranmış mübahisələri Tərəflər</li>
                  <li>Tərəflər arasında yaranmış mübahisələr bu Müqavilənin 10.2-ci və 10.3-cü bəndlərində göstərilən qaydada həll edilmədikdə mübahisələrə Azərbaycan Respublikasının müvafiq qanunvericiliyinə uyğun olaraq müvafiq məhkəmələrdə baxılır.</li>
                  <li>Tərəflər razılığa gəlirlər ki, mübahisələrin məhkəmə qaydasında həll edilməsi zamanı səlahiyyətli məhkəmə Şirkətin hüquqi ünvanının olduğu yerin məhkəmələridir.</li>
              </ol>
          </li><br/>
          <li>
              <h5>YEKUN MÜDDƏALAR</h5>
              <ol>
                  <li>İstifadəçi özünün İstifadəçi Adını və Şifrələrini hər zaman tam məxfi saxlamalı və heç bir şəxsə bu məlumatları birbaşa və ya dolayısı yolla açıqlamamalıdır.</li>
                  <li>İstifadəçi onun tərəfindən seçilmiş istifadəçi adı və şifrələrin təhlükəsiz istifadəsi və qorunması üçün birbaşa məsuliyyət daşıyır.</li>
                  <li>Şirkət Mobil tətbiqdən istifadə şərtlərinin pozulması halları olduqda Mobil tətbiqin bütövlükdə və ya bir hissəsinin cari və ya gələcək istifadəsini dayandırmaq hüququna malikdir. Bu dəyişikliklər İstifadəçinin hesabının dayandırılması və ya hesaba girişin məhdudlaşdırılması, hesabdakı bütün məlumatların itirilməsi və silinməsi ilə nəticələnə bilər. Hesab dayandırıldıqda və ya ləğv edildikdə buna əsas yaradan hallar aradan qaldırıldıqdan sonra İstifadəçi tərəfindən hesabın bərpası barədə Şirkətə müraciət edilə bilər.</li>
                  <li>Şirkət malik olduğu bütün əqli mülkiyyət hüquqlarının və Ticarət nişanının sahibidir. Bura Vebsayt, Mobil tətbiq, sosial şəbəkələr və digər resurslar daxildir. Mobil tətbiqdən istifadə zamanı portalın və ya məzmunun hər hansı proqram təminatı vasitəsilə monitorinqi və ya səhifəyə aid müəllif hüquqlarının pozulması yolverilməzdir.</li>
                  <li>İstifadəçi tərəfindən Mobil tətbiq üzərindən əqli mülkiyyət hüququnu pozan, hədə, böhtan, pornoqrafik xarakter daşıyan, təhqiramiz, nalayiq, təhrikçi materialların və ya Azərbaycan Respublikasının qanunvericiliyinə zidd davranışa səbəb olan və yaxud təhrik hər hansı fəaliyyətlə məşğul olmaq qadağandır.</li>
                  <li>Bu Müqavilənin heç bir müddəası Şirkətin əmtəə nişanlarından və ya loqolarından başqa məqsədlər üçün istifadənin mümkün olması kimi təfsir oluna bilməz.</li>
                  <li>Xidmətdən imtina ilə bağlı İstifadəçiyə məlumatların təqdim edilməməsi nəticəsində yaranacaq hər hansı hala görə Şirkət məsuliyyət daşımır.</li>
              </ol>
          </li><br/>
          <li>
              <h5>ŞİRKƏTİN HÜQUQİ ÜNVANI VƏ REKVİZİTLƏRİ</h5>
              <table>
                  <tbody>
                    <tr>
                        <td><strong>Şirkət</strong></td>
                        <td>"DİGİTAL UMBRELLA" Məhdud Məsuliyyətli Cəmiyyəti</td>
                    </tr>
                    <tr>
                        <td><strong>Hüquqi ünvanı: </strong></td>
                        <td>AZ1073, Bakı şəhəri Yasamal rayonu, Mətbuat pr., ev 35C, m.126</td>
                    </tr>
                    <tr>
                        <td><strong>VÖEN: </strong></td>
                        <td>1307430271</td>
                    </tr>
                    <tr>
                        <td><strong>Vergi uçotu orqanının adı: </strong></td>
                        <td>İqtisadiyyat Nazirliyi yanında Dövlət Vergi Xidmətinin Bakı şəhəri Lokal Gəlirlər Baş İdarəsi</td>
                    </tr>
                  </tbody>
              </table>
          </li>
      </ol>
    </div>
);
