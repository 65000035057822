import React from "react";

const Main = () => (
  <section>
    <body className="main" id="main">
      <div className="container">
        <div className="row main__row">
          <div className="main__left col-sm-12 col-md-12 col-lg-7">
            <h1>Yonca ilə təsərrüfatını daha məhsuldar et</h1>
            <div className="download">
              <div className="download__qr d-none d-lg-block">
                <img alt="qr-code" src={require("assets/images/qr-code.png")} />
              </div>
              <div className="download__icon">
                <img
                  alt="google-play"
                  src={require("assets/images/google-play.svg").default}
                />
                <p className="download__icon__txt d-none d-lg-block">
                  🤩 Mobil tətbiqi asan endirmək üçün QR code - u oxudun.
                </p>
              </div>
            </div>
          </div>
          <div className="main__right col-sm-12 col-md-12 col-lg-5">
            <div className="main__right__background">
              <div className="round-icon main__right__background__doc">
                <img
                  alt="document-icon"
                  src={require("assets/images/icons/document_icon.svg").default}
                />
              </div>
              <div className="round-icon main__right__background__alert">
                <img
                  alt="alert-icon"
                  src={require("assets/images/icons/alert_icon.svg").default}
                />
              </div>
              <img
                alt="iphone"
                className="main__right__mobile"
                src={require("assets/images/mainpage-mobile.png")}
              />
            </div>
          </div>
        </div>
      </div>
      <img
        className="arrow"
        alt="arrow"
        src={require("assets/images/icons/arrow.svg").default}
      />
    </body>
  </section>
);

export default Main;
