import React from "react";

export const About = () => {
  return (
    <>
      <section className="about container" id="about">
        <div className="row">
          <div className="about__body col-sm-12 col-md-12 col-lg-12">
            <p className="grey_background_title m-auto">
              <img
                alt="leaf"
                className="small_logo_txt"
                src={require("assets/images/icons/logo-leaf_icon.svg").default}
              />
              Yonca nə üçündür ?
            </p>
            <h1>Yonca uğur gətirir!</h1>
            <p>
              Hər sahədə olduğu kimi kənd təsərrüfatında da rəqəmsallaşırıq!
              <br />
              Kənd təsərrüfatı sahəsində rəqəmsal həllər təqdim edən şirkət
              olaraq Digital Umbrella Yonca startup layihəsini fermerlərin
              istifadəsinə verir. Yonca kənd təsərrüfatı sahəsində olan
              xidmətlərə və məlumatlara əlçatanlığın artırılmasına xidmət edən
              mobil tətbiqdir.
            </p>
          </div>
        </div>
      </section>
      <section className="services">
        <div className="container">
          <div className="services__body row">
            <div className="services__body__box col-12 col-sm-12 col-md-12 col-lg-4">
              <div className="services__body__box__icon">
                <img
                  alt="alert-icon"
                  src={
                    require("assets/images/icons/alert-green_icon.svg").default
                  }                />
              </div>
              <h3>Bildirişlər</h3>
              <p>
                Təsərrüfat ilə əlaqəli bütün məlumatlar artıq ağıllı
                telefonlardadır.
              </p>
            </div>
            <div className="services__body__box col-12 col-sm-12 col-md-12 col-lg-4">
              <div className="services__body__box__icon">
                <img
                  alt="document-icon"
                  src={
                    require("assets/images/icons/document-green_icon.svg")
                      .default
                  }
                />
              </div>
              <h3>Əkin bəyanı və subsidiya</h3>
              <p>
                İstifadəçi dostu interfeys ilə əkin bəyanı və subsidiya
                müraciəti əvvəlkindən daha asandır.
              </p>
            </div>
            <div className="services__body__box col-12 col-sm-12 col-md-12 col-lg-4">
              <div className="services__body__box__icon">
                <img
                  alt="pointer-icon"
                  src={
                    require("assets/images/icons/pointer-green_icon.svg")
                      .default
                  }
                />
              </div>
              <h3>Müraciətlər</h3>
              <p>
                Kənd təsərrüfatı xidmətlərinə müraciət Yonca ilə daha rahatdır.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
