import React from "react";

export const Privacy = () => (
  <div className="ps-4 pt-2 pb-4">
    <ol>
      <li>
        <h6>Məxfilik bildirişi</h6>
        <p>
          Bu Məxfilik Siyasəti Yonca mobil tətbiqi (bundan sonra – Mobil tətbiq) İstifadəçilərinin
          məlumatlarının necə istifadə edildiyi barədə İstifadəçiləri məlumatlandırmaq məqsədilə hazırlanmışdır.
          <br />
          Məxfilik Siyasəti vasitəsilə İstifadəçilərə Digital Umbrella Məhdud Məsuliyyətli Cəmiyyətinin
          (bundan sonra – “Şirkət”) məlumatları necə toplaması, saxlaması, istifadə etməsi və üçüncü şəxslərə açıqlaması barədə məlumat verilir.
          <br />
          Mobil tətbiqdən keçid etməklə dövlət və qeyri-dövlət qurumları (bundan sonra - Qurum) tərəfindən göstərilən xidmətlərdən istifadə zamanı məlumatların mühafizə edilməsinə münasibətdə xidmət göstərən qurumun öz Məxfilik Siyasəti də tətbiq oluna bilər. Mobil tətbiqdən keçid etməklə Qurumlar tərəfindən göstərilən xidmətlərdən istifadə edilməsi İstifadəçi tərəfindən həmin Qurumun Məxfilik Siyasəti ilə razı olması kimi başa düşülür və bu zaman Şirkət xidmətlərindən istifadə edilən Qurumun əməlləri üçün cavabdehlik daşımır. Əgər siz Mobil tətbiqdə şəxsi hesab açmısınızsa, bu sizin Müqavilənin və bu Məxfilik Siyasətinin müddəaları ilə tam razı olduğunuz mənasına gəlir və bu Məxfilik Siyasətində “Siz” ifadəsinin işlədildiyi bütün müddəalara öz razılığınızı ifadə edirisiniz.
        </p>
      </li>
      <li>
        <h6>Şəxsi məlumatların toplanması</h6>
        <p>
           2.1. Şirkət Mobil tətbiqi idarə etmək və daha da təkmilləşdirmək üçün sizin haqqınızda şəxsi məlumatları toplayır. Bu məlumatlar 3 kateqoriyaya bölünür:
            <ul>
              <li>adınız və soyadınız</li>
              <li>elektron poçt ünvanınız</li>
              <li>mobil telefon nömrəniz</li>
              <li>parolunuz</li>
              <li>şəxsiyyət vəsiqənizin fərdi identifikasiya nömrəsi</li>
              <li>gizli suallarınız və cavablarınız</li>
              <li>bildirişləri almaq üçün seçdiyiniz metod</li>
              <li>Mobil tətbiqdən istifadə ilə bağlı etmiş olduğunuz bütün maliyyə əməliyyatları və ödənişlərlə bağlı məlumatlar (məsələn ödəniş vasitəsi kimi istifadə edilmiş bank kartının nömrəsi, son istifadə tarixi, CVV kodu)</li>
            </ul>
          </p>
          <p>2.2. Mobil tətbiqdən istifadə ilə əlaqədar Mobil tətbiqin özü tərəfindən yaradılan məlumatlar: Mobil tətbiqin keyfiyyətinin yüksəldilməsi məqsədilə istifadə edilən bu məlumatlar şəxsin fərdi olaraq identifikasiya edilməsinə imkan verməsə də, bəzi hallarda fərdin şəxsiyyətinin müəyyən edilməsi üçün istifadə edilə bilər. Bu məlumatlara aşağıdakılar aiddir:
            <ul>
              <li>Mobil tətbiqdə istifadəçi adınızı</li>
              <li>
                Mobil tətbiq hesabınızla əlaqəli fəaliyyət qeydləri, məsələn: hesaba giriş cəhdləri,
                Xidmətlərlərlə əlaqəli olaraq daxil olduğunuz səhifələr və mesajlar.
              </li>
              <li>İstifadə etdiyiniz mobil cihazınızın adı, modeli, əməliyyat sistemi və IP ünvanı</li>
              <li>İstifadə etdiyiniz Mobil tətbiqin versiyası</li>
              <li>İstifadə etdiyiniz internet provayderinin adı, əlaqə vasitənizin forması</li>
              <li>Məkan və zaman</li>
            </ul>
          </p>
          <p>2.3. Qurumlardan əldə edilmiş sizinlə əlaqəli digər məlumatlar: Bu məlumatlara sizə aid digər Qurumların məlumat bazasında mövcud olan məlumatlar aiddir.</p>
      </li>
      <li>
        <h6>Fərdi məlumatlara giriş üçün cihaz icazələr</h6>
        <p>
          İstifadəçinin xüsusi cihazından asılı olaraq, Mobil tətbiq aşağıda təsvir olunduğu kimi İstifadəçinin cihaz məlumatlarına daxil olmağa imkan verən müəyyən icazələri tələb edə bilər. Müvafiq məlumatlar əldə edilməzdən əvvəl bu icazələr İstifadəçi tərəfindən verilməlidir. İcazə verildikdən sonra istifadəçi tərəfindən istənilən vaxt ləğv edilə bilər. Cihaz icazələrinə nəzarət üçün dəqiq prosedur İstifadəçinin cihazı və proqramından asılı ola bilər. Unutmayın ki, bu cür icazələrin ləğvi Mobil tətbiqin düzgün işləməsinə təsir göstərə bilər. İstifadəçi aşağıda sadalanan hər hansı bir icazəni verərsə, müvafiq şəxsi məlumatlar Mobil tətbiq tərəfindən işlənilə bilər (yəni daxil olmaq, dəyişdirmək və ya çıxarmaq):
          <br />
          Kamera icazəsi: Kameraya daxil olmaq və ya cihazdan şəkillər və video çəkmək üçün istifadə olunur;
          <br />
          Sənədlərin saxlanması icazəsi (“Storage permission”): Mobil tətbiq tərəfindən yaradılan sənədlər Şirkət tərəfindən müəyyən edilmiş müddətdə saxlanılır.
        </p>
      </li>
      <li>
        <h6>Şəxsi məlumatlarınızı niyə toplayırıq</h6>
        <p>
          Şirkət Mobil tətbiqin xidmətlərini idarə etmək üçün şəxsi məlumatlarınızı toplayır, istifadə edir və açıqlayır, sizi Mobil tətbiqin bildirişləri ilə təmin edir və məlumatları Qurumlar vasitəsilə yeniləməyə imkan verir. Bu halda məlumatlarınızın toplanması, istifadəsi və açıqlanmasına daxildir:
          <ul>
            <li>Mobil tətbiq hesabınızı yaratmağa və daxil olmağa imkan vermək</li>
            <li>Mobil tətbiq hesabınızı idarə etmək, qorumaq və hesabınızla bağlı sizə dəstək vermək</li>
            <li>Mobil tətbiq hesabınızı Qurumların şəxsi kabinetləri ilə əlaqələndirməyə imkan verir</li>
            <li>Qurumlardan bildirişlər almağa imkan vermək</li>
            <li>Qurumdan yeni bir mesaj aldığınız zaman sizə üstünlük verdiyiniz e-poçt adresinizə və ya mobil nömrənizə e-poçt və ya mətn mesajı ilə məlumat vermək.</li>
          </ul>
        </p>
        <p>
          Şirkət tərəfindən sizin məlumatlarının analitika, reklam və digər bu kimi məqsədlərlə başqa şəxslərlə paylaşıla bilər. Bu zaman Şirkət məlumatın paylaşıldığı üçüncü şəxsin pozuntuları üçün cavabdehlik daşımır. Digər şəxslərlə bölüşdürülə bilən məlumatlar həmin məlumatların verildiyi subyektlərin dairəsindən və məqsəddən asılı olaraq aşağıdakı kimi qruplaşdırılır:
          <ul>
            <li>Reklam məqsədli bölüşdürülmə: Mobil tətbiqdə reklam yerləşdirən subyektlərlə onların reklamlarının izlənilmə sayı və izləyici profilinin xüsusiyyətləri barədə məlumatlar bölüşülə bilər. Belə məlumatlar qismində neçə nəfərin reklamı izlədiyi, neçə nəfərin izlədiyi reklamdan sonra məhsulla maraqlandığı kimi məlumatlar aid edilə bilər. Buna baxmayaraq, sizin ad və soyad kimi fərdi məlumatlarının birbaşa olaraq reklam yerləşdirən subyektlərlə birbaşa olaraq paylaşılmır.</li>
            <li>
              Analitika məqsədli bölüşdürmə: Əldə edilmiş məlumatların analitik subyektlərlə paylaşılması əsasında Mobil tətbiqin fəaliyyətinin qiymətləndirilməsi mümkündür. Bu zaman demoqrafik, cinsi, yaş kimi xüsusiyyətləri nəzərə alınmaqla, Mobil tətbiqin təkmilləşdirilməsi, habelə hədəf olan müştərilərin zövqlərinə uyğun məhsul və xidmət təklif edilməsi Şirkət və reklam yerləşdirən digər subyektlər tərəfindən müəyyən edilə bilər.
            </li>
            <li>
              Mal və xidmət təklif edən partnyorlarla bölüşdürmə: Mobil tətbiqdən istifadə edərək sizə mal və ya xidmət təklif etmiş hər hansı partnyora elektron şəkildə ödəniş edildikdə, ödəniş əməliyyatına dair bütün məlumatlarınız müvafiq mal və ya xidməti təklif edən partnyorla paylaşılır.
            </li>
            <li>
              Xidmət göstərən subyektlərlə bölüşdürmə: Şirkət tərəfindən xidmətlərindən istifadə edilən subyektlərlə paylaşıla bilər. Məsələn Şirkət Mobil tətbiqin təkmilləşdirilməsi, şübhəli əməliyyatların araşdırılması kimi xidmətlərdən istifadəyə başqa subyekt cəlb edirsə sizin məlumatlarınız həmin şəxslərlə paylaşıla bilər.
            </li>
            <li>
              Tədqiqat məqsədli bölüşdürmə: Sosial məqsəd, innovativ inkişaf, ictimai təhlükəzilik, sağlamlıq kimi tədqiqatlar aparılması məqsədilə sizin məlumatlarının tədqiqat aparan subyektlərlə paylaşıla bilər.
            </li>
          </ul>
        </p>
      </li>
      <li>
        <h6>Çərəzlər</h6>
        <p>
          Şirkət sessiya əsaslı kukilərdən istifadə edir. Kukilərdən istifadə edilməsi Mobil tətbiqdən istifadəni optimallaşdırır. Belə ki, bu yolla Şirkət tərəfindən Mobil tətbiqin təkmilləşdirilməsi mümkün olur. Sessiya əsaslı kukilər kompüterinizə köçürülən və brauzerinizi bağladıqda silinən müvəqqəti kuki fayllarıdır. Bu kukilər Mobil tətbiqə daxil olunduqdan sonra onun daxilində funksiyaları yerinə yetirməyə imkan vermək və Mobil tətbiqi yaxşılaşdırmaq üçün anonim veb istifadə məlumatları toplamaq üçün istifadə olunur. Şirkət davamlı kuki istifadə etmir. Bunlar silinməyincə və ya istifadə müddəti bitməyincə sabit diskinizdə olan kukilərdir. Kukilərin komputerinizə zərər verməməsinə baxmayaraq, bu faylları rədd etməyiniz Mobil tətbiqdəki bəzi funksiyalara təsir edə bilər. Şirkət Mobil tətbiq xidmətlərindən kənarda gəzən fəaliyyətlərinizi müəyyən etmək üçün kukilərdən istifadə etmir.
        </p>
      </li>
      <li>
        <h6>Düzəlişlər</h6>
        <p>
          “Yonca” Mobil tətbiq hesabınız “Elektron Hökumət İnformasiya Sistemi” vasitəsilə dövlət informasiya sistemləri və ehtiyatlarında olan məlumatlar əsasında formalaşdırılır. Sizin tərəfinizdən təqdim edilmiş məlumatlar və Mobil tətbiqdən istifadə ilə əlaqədar Mobil tətbiqin özü tərəfindən yaradılan məlumatlar istisna olmaqla, digər məlumatlara dəyişikliklər yalnız Qurumlar tərəfindən edilə bilər. Məlumatlarda çatışmazlıq və ya dəyişiklik olduğu halda bu barədə müvafiq informasiya sistemi və ehtiyatının sahibinə Mobil tətbiq vasitəsilə də müraciət edə bilərsiniz.
        </p>
      </li>
    </ol>
  </div>
);
